<template>
	<div>
		<div class="hidden sm:flex flex-row flex-wrap justify-end items-center w-full h-28 py-6 pr-10 pl-20 bg-white filter shadow-default z-20 gap-5">
			<!-- <div class="flex flex-row flex-wrap justify-end items-center w-full h-28 py-6 pr-10 pl-20 bg-white filter shadow-default z-20"> -->
			<div class="text-md text-gray-500 font-normal">Кол-во анкет обновлено: {{ returnDate }}</div>
			<div class="flex flex-row items-center h-8 text-sm text-blue-500" id="specialButton" style="cursor: pointer" alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ">
				<span class="text-3xl mr-1">&#128065;</span>
				<p>версия для слабовидящих</p>
			</div>
			<!-- help -->
			<a v-if="getGlobalListLinkToAnkets.length > 0" class="flex flex-row items-center h-8 text-sm text-blue-500" href="" @click.prevent="openModal('Список анкет', false, true)">
				<ankets />
				Анкеты
			</a>
			<a
				:class="{ 'mr-8': getGlobalListLinkToAnkets.length > 0, 'mr-24': getGlobalListLinkToAnkets.length === 0 }"
				class="flex flex-row items-center h-8 text-sm text-blue-500"
				href=""
				@click.prevent="openModal('Помощь', true, false)"
			>
				<help class="mr-2" />
				Помощь
			</a>
			<!-- user data -->
			<div class="flex items-center w-56 h-16">
				<img class="flex mr-5 w-11 h-11" :src="calcUrlImg" width="44" height="44" alt="Аватар" />
				<p class="flex text-sm text-gray-900 font-medium break-words">{{ getFio }}</p>
			</div>
			<!-- exit -->
			<a class="flex" href="" @click.prevent="logout">
				<exit />
			</a>
		</div>

		<div class="sm:hidden items-center flex justify-between flex-row w-screen py-3 px-4 bg-white shadow-default z-1">
			<div class="flex items-center">
				<img src="@/assets/img/logo.png" alt="Логотип" width="37" />
				<p class="font-bold text-min pl-4 leading-3">Портал независимой оценки качества условий оказания услуг медицинскими организациями</p>
			</div>
			<div class="cursor-pointer h-4" @click.prevent="openMenu()">
				<!-- <div class="cursor-pointer h-4" @click.prevent="openMenu()"> -->
				<Burger />
			</div>
		</div>

		<transition name="fade">
			<div v-if="isModalVisible" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
				<div class="relative w-4/12 my-6 mx-auto max-w-6xl">
					<!--content-->
					<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<!--header-->
						<div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t-lg bg-blue-50">
							<h3 class="text-xl font-semibold">{{ modal.title }}</h3>
							<span class="cursor-pointer text-blue-500" @click="isModalVisible = false">
								<Close />
							</span>
						</div>
						<!--body-->
						<div class="relative p-6 flex-auto" v-if="modal.viewInfo">
							<p><a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/docs/НОК_Ролевая_модель.pdf" target="_blank" rel="noopener noreferrer"> Ролевая модель </a></p>
							<p class="mt-4">
								<a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/Files/НОК_Руководство пользователя.pdf" target="_blank" rel="noopener noreferrer">
									Руководство пользователя
								</a>
							</p>
							<p class="mt-8 my-4 text-blueGray-500 text-lg leading-relaxed">Служба технической поддержки:</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">Часы работы: 09:00-18:00 (МСК)</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">8 (495) 627-29-61 | +7 (901) 756-94-65</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">nok-support@rosminzdrav.ru</p>
						</div>

						<div class="relative p-6 flex-auto" v-if="modal.viewListsAnket">
							<ul>
								<li v-for="(item, key) in getGlobalListLinkToAnkets" :key="'AA' + key">
									<span class="underline text-blue-500 cursor-pointer" @click="routerLink({ name: 'Anket', params: { anketaResultId: item.Id } })">{{ item.Name }}</span>
								</li>
							</ul>
						</div>
						<!--footer-->
						<!-- <div class="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
							<button class="text-white bg-red-500 border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
								Да
							</button>
							<button class="text-white bg-indigo-600 font-bold px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
								Нет
							</button>
						</div> -->
					</div>
				</div>
			</div>
		</transition>

		<div v-if="isModalVisible" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>

<script>
// Глобальные данные стора
import { actions, getters, mutations } from '@/store/store';
import Burger from '../assets/img/burger.svg';
import Close from '../assets/img/close.svg';
import help from '../assets/img/help.svg';
import ankets from '../assets/img/assingMO.svg';
import exit from '../assets/img/exit.svg';
import defaulImg from '../assets/img/avatarDefault.png';
import uhpv from '@/plugins/uhpv-full.min';

export default {
	name: 'Header',
	components: {
		help,
		exit,
		Close,
		Burger,
		ankets
	},
	computed: {
		...getters,
		getFio() {
			const user = this.getAuthUser;

			return `${user.fname ? user.fname : ''} ${user.iname ? user.iname : ''} ${user.oname ? user.oname : ''}`;
		},
		calcUrlImg() {
			let res = '';
			const user = this.getAuthUser;

			if (user.photoUrl && user.photoUrl.length > 0 && user.photoUrl[0] === 'h') {
				res = user.photoUrl;
			}

			if (user.photoUrl && user.photoUrl.length > 0 && user.photoUrl[0] === '/') {
				res = `${this.$http.defaults.baseURL}${user.photoUrl.slice(1, user.photoUrl.length)}`;
			}

			return res === '' ? defaulImg : res;
		},
		returnDate() {
			let res = '---';

			if (this.updateDate) res = `${new Date(this.updateDate.split('T')[0]).toLocaleDateString()} ${this.updateDate.split('T')[1].substr(0, 8)}`;

			return res;
		}
	},
	data: () => ({
		updateDate: null,
		isModalVisible: false,
		modal: {
			title: '',
			viewInfo: false,
			viewListsAnket: false
		}
	}),
	methods: {
		...actions,
		...mutations,
		uhpv,
		routerLink(link) {
			this.isModalVisible = false;
			this.$router.push(link);
		},
		logout() {
			this.$router.replace('/');
			mutations.resetGlobalUser();
		},
		openModal(title, viewInfo, viewListsAnket) {
			this.modal = { title, viewInfo, viewListsAnket };
			this.isModalVisible = true;
		}
	},
	created() {
		this.$http.get('MedicalOrgs/GetCalculateLastDatetime').then((res) => {
			this.updateDate = res.data[0].LastUpdated;
		});
		uhpv();
	}
};
</script>

<style scoped>
.fade-enter {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 500ms ease-out;
}

.fade-leave-to {
	opacity: 0;
}
</style>
