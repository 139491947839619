<template>
	<div class="flex flex-row flex-grow" v-if="authUser">
		<Menu>
			<template v-slot:li>
				<li v-for="(item, key) in menuList" :key="'A' + key" v-show="calculateView(item.accessRole, getAuthUser.roles[0])">
					<div class="flex flex-row h-14 mr-auto mb-6 ml-5 items-center text-sm cursor-pointer" :class="{ active: tabIsActive(item, menu) }" @click.prevent="goRoute(item)">
						<div class="flex justify-center items-center h-8 min-h-8 w-8 min-w-8">
							<Icons :icon-name="item.icon" />
						</div>
						<p class="flex p-2" v-if="menu">{{ item.name }}</p>
					</div>
					<ul v-if="item.subItems && menu">
						<li
							v-for="(subitem, subKey) in item.subItems"
							:key="'sub' + subKey"
							class="flex pl-8 flex-row h-14 mr-auto mb-6 ml-5 items-center text-sm cursor-pointer"
							:class="{ active: $route.path == subitem.router }"
							@click.prevent="goRoute(subitem)"
						>
							<p class="flex p-2 relative" v-if="menu">{{ subitem.name }}</p>
						</li>
					</ul>
				</li>
			</template>
		</Menu>
		<div class="flex flex-col flex-grow max-w-full w-11/12">
			<Header></Header>
			<router-view />
		</div>
	</div>

	<router-view v-else />
</template>

<script>
import Menu from '../../components/Menu.vue';
import Header from '../../components/Header.vue';
import Icons from '../../components/Icons.vue';

// Глобальные данные стора
import { getters } from '../../store/store';

export default {
	name: 'OsMember',
	components: {
		Menu,
		Header,
		Icons
	},
	computed: {
		...getters,
		calculateActiveTab() {
			console.log('calculateActiveTab', this.$route.name);
			switch (this.$route.name) {
				case 'Activities':
					return 0;
				case 'InfoMO':
					return 1;
				case 'ControlEvent':
					return 2;
				case 'AssignMO':
					return 3;
				default:
					return false;
			}
		}
	},
	data: () => ({
		menuList: [
			{
				name: 'Активность членов ОС',
				accessRole: ['OS_Secretary', 'OS_Chairman'],
				router: '/osmember/activities',
				active: '/osmember/activities',
				icon: 'active'
			},
			{
				name: 'Информация по медицинским организациям',
				accessRole: ['OS_Secretary', 'OS_Chairman'],
				router: '/osmember/infoMO',
				active: '/osmember/infoMO',
				icon: 'info'
			},
			{
				name: 'Контрольные мероприятия',
				accessRole: ['OS_Secretary', 'OS_Chairman', 'OS_Member'],
				router: '/osmember/controlEvent',
				active: '/osmember/controlEvent',
				icon: 'control'
			},
			{
				name: 'Назначение медицинских организаций',
				accessRole: ['OS_Secretary'],
				router: '/osmember/assignMO',
				active: '/osmember/assignMO',
				icon: 'assign'
			},
			{
				name: 'Архив',
				accessRole: ['OS_Secretary', 'OS_Chairman', 'OS_Member'],
				router: '/osmember/archive',
				active: '/osmember/archive',
				icon: 'driver'
			}
		]
	}),
	methods: {
		calculateView(accessRole, getAuthUserRoles) {
			let res = false;

			for (let i = 0; i < accessRole.length; i += 1) {
				if (accessRole[i] === getAuthUserRoles) {
					res = true;

					break;
				}
			}

			return res;
		},
		goRoute(item) {
			if (item.router === 'fedmedorg') {
				let routeData = this.$router.resolve({ path: '/ogv/general', query: { regionId: '022b0e2d-5b3d-4ccd-8737-fd05b7967d3f' } });
				window.open(routeData.href, '_blank');
				return;
			}

			if (item.subItems !== undefined && item.subItems.length > 0) {
				item = item.subItems[0];
			}

			if (item.router !== this.$route.path) {
				this.$router.push({ path: item.router, query: this.$route.query });
			}
		},
		tabIsActive(item, menu) {
			if (item.active === this.$route.path) {
				return true;
			}

			if (!menu && item.subItems !== undefined) {
				for (let i = 0; i < item.subItems.length; i += 1) {
					if (item.subItems[i].router === this.$route.path) {
						return true;
					}
				}
			}

			return false;
		}
	}
};
</script>

<style scoped>
.open {
	color: #3377ff;
}
.active svg path {
	fill: #37f !important;
}
</style>
