<template>
	<div class="menu">
		<div class="logo hidden relative sm:flex flex-col flex-grow items-center w-36 max-w-1/12 h-full min-h-screen m-0 p-9 bg-white filter shadow-default z-30" :class="{ 'w-auto open': menu }">
			<burger-comp v-if="!isQuestionnaire" class="absolute top-10 -right-14 cursor-pointer" @click.prevent="openMenu()" />
			<img class="w-16" src="@/assets/img/logo.png" />
			<p class="flex w-72 h-12 mt-8 text-xs text-center uppercase" v-if="menu">Портал независимой оценки качества условий оказания услуг медицинскими организациями</p>
			<p class="flex w-72 h-12 mt-8 text-xs text-center uppercase font-bold" :class="{ 'mb-5': menu }" v-if="menu && !isOGV">Министерство здравоохранения Российской Федерации</p>
			<p class="flex w-72 h-12 mt-8 text-xs text-center uppercase font-bold" :class="{ 'mb-5': menu }" v-if="menu && this.getAuthUser.position && isOGV">{{ this.getAuthUser.position }}</p>
			<p class="label border-t border-gray-100 w-full" v-if="menu && region !== null">Регион</p>
			<p class="region w-full" :class="{ 'mb-5': menu }" v-if="menu && region !== null">{{ region.name }}</p>
			<ul class="flex flex-col w-full mb-auto pb-20 pt-12" :class="{ 'mt-10': !menu }">
				<slot name="li" />
			</ul>
		</div>

		<div class="sm:hidden fixed bottom-0 top-0 z-30 w-screen transition-all" :class="{ 'left-0': menu, '-left-full': !menu, 'opacity-100': menu, 'opacity-0': !menu }">
			<div class="flex justify-between relative flex-col flex-grow items-center h-full w-10/12 bg-white z-30 pt-10">
				<div class="flex items-center w-full pl-4">
					<img class="flex" src="@/assets/img/avatarDefault.png" width="66" height="66" alt="Аватар" />
					<p class="text-gray-900 text-sm pl-6">
						<span class="block">{{ this.getAuthUser.fname }}</span>
						<span class="block">{{ this.getAuthUser.iname }} {{ this.getAuthUser.oname }}</span>
					</p>
				</div>
				<ul class="flex flex-col w-full pt-6 border-t border-gray-100 mt-9">
					<slot name="li" />
				</ul>
				<ul class="flex flex-col w-full pt-6 border-t border-gray-100 mt-9">
					<li class="flex pl-5 flex-row py-3 items-center text-sm cursor-pointer" @click.prevent="isModalVisible = true">
						<div class="flex justify-center items-center h-8 min-h-8 w-8 min-w-8">
							<svg width="26" height="26" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
								<!-- eslint-disable max-len -->
								<path
									d="M8.66666 25.0625C8.36332 25.0625 8.04914 24.9867 7.76747 24.835C7.14997 24.51 6.77082 23.86 6.77082 23.1667V21.6284C3.49916 21.2926 1.35416 18.8875 1.35416 15.4425V8.94255C1.35416 5.21588 3.85666 2.71338 7.58332 2.71338H18.4167C22.1433 2.71338 24.6458 5.21588 24.6458 8.94255V15.4425C24.6458 19.1691 22.1433 21.6716 18.4167 21.6716H14.3325L9.71746 24.7484C9.40329 24.9542 9.03499 25.0625 8.66666 25.0625ZM7.58332 4.32754C4.78832 4.32754 2.97916 6.1367 2.97916 8.9317V15.4318C2.97916 18.2268 4.78832 20.0359 7.58332 20.0359C8.02749 20.0359 8.39582 20.4043 8.39582 20.8484V23.1559C8.39582 23.2968 8.48249 23.3617 8.53666 23.3942C8.59083 23.4267 8.69917 23.4592 8.81834 23.3834L13.6392 20.1768C13.7692 20.0901 13.9317 20.0359 14.0942 20.0359H18.4275C21.2225 20.0359 23.0317 18.2268 23.0317 15.4318V8.9317C23.0317 6.1367 21.2225 4.32754 18.4275 4.32754H7.58332V4.32754Z"
									fill="#6A6E83"
								/>
								<path
									d="M12.9998 14.0017C12.5556 14.0017 12.1873 13.6334 12.1873 13.1892V12.9618C12.1873 11.7051 13.1081 11.0876 13.4548 10.8492C13.8556 10.5784 13.9856 10.3942 13.9856 10.1126C13.9856 9.57091 13.5415 9.12671 12.9998 9.12671C12.4581 9.12671 12.014 9.57091 12.014 10.1126C12.014 10.5567 11.6457 10.9251 11.2015 10.9251C10.7573 10.9251 10.389 10.5567 10.389 10.1126C10.389 8.67175 11.559 7.50171 12.9998 7.50171C14.4406 7.50171 15.6106 8.67175 15.6106 10.1126C15.6106 11.3476 14.7007 11.9651 14.3648 12.1926C13.9423 12.4742 13.8123 12.6584 13.8123 12.9618V13.1892C13.8123 13.6442 13.444 14.0017 12.9998 14.0017Z"
									fill="#6A6E83"
								/>
								<path
									d="M13 16.6992C12.545 16.6992 12.1875 16.3309 12.1875 15.8867C12.1875 15.4426 12.5558 15.0742 13 15.0742C13.4442 15.0742 13.8125 15.4426 13.8125 15.8867C13.8125 16.3309 13.455 16.6992 13 16.6992Z"
									fill="#6A6E83"
								/>
								<!-- eslint-enable max-len -->
							</svg>
						</div>
						<p class="flex p-2">Помощь</p>
					</li>
					<li class="flex pl-5 flex-row py-3 items-center text-sm cursor-pointer" @click.prevent="logout()">
						<div class="flex justify-center items-center h-8 min-h-8 w-8 min-w-8">
							<svg width="26" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12.3333 17L19 11M19 11L12.3333 5M19 11L7 11" stroke="#6A6E83" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M7 21L5 21C2.79086 21 1 19.7208 1 18.1429L1 3.85714C1 2.27919 2.79086 0.999999 5 0.999999L7 1"
									stroke="#6A6E83"
									stroke-width="1.7"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<p class="flex p-2">Выйти</p>
					</li>
				</ul>
			</div>
			<div class="fixed left-0 right-0 top-0 bottom-0 z-10 bg-black50" @click.prevent="openMenu()" :class="{ hidden: !menu, show: menu }"></div>
		</div>

		<transition name="fade">
			<div v-if="isModalVisible" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
				<div class="relative w-11/12 my-6 mx-auto max-w-6xl">
					<!--content-->
					<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<!--header-->
						<div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t-lg bg-blue-50">
							<h3 class="text-xl font-semibold">Помощь</h3>
							<span class="cursor-pointer text-blue-500" @click="isModalVisible = false">
								<Close />
							</span>
						</div>
						<!--body-->
						<div class="relative p-6 flex-auto">
							<p><a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/docs/НОК_Ролевая_модель.pdf" target="_blank" rel="noopener noreferrer"> Ролевая модель </a></p>
							<p class="mt-4">
								<a class="text-blue-500 underline" href="https://nok.minzdrav.gov.ru/Files/НОК_Руководство пользователя.pdf" target="_blank" rel="noopener noreferrer">
									Руководство пользователя
								</a>
							</p>
							<p class="mt-8 my-4 text-blueGray-500 text-lg leading-relaxed">Служба технической поддержки:</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">Часы работы: 09:00-18:00 (МСК)</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">8 (495) 627-29-61 | +7 (901) 756-94-65</p>
							<p class="my-4 text-gray-500 text-md leading-relaxed">nok-support@rosminzdrav.ru</p>
						</div>
						<!--footer-->
						<!-- <div class="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
							<button class="text-white bg-red-500 border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
								Да
							</button>
							<button class="text-white bg-indigo-600 font-bold px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" v-on:click="toggleModal()">
								Нет
							</button>
						</div> -->
					</div>
				</div>
			</div>
		</transition>

		<div v-if="isModalVisible" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>

<script>
// Глобальные данные стора
import { actions, getters, mutations, methods } from '@/store/store';
import burgerComp from '../assets/img/burger.svg';
import Close from '../assets/img/close.svg';

export default {
	name: 'Menu',
	components: {
		burgerComp,
		Close
	},
	data() {
		return {
			isModalVisible: false,
			isOGV: false,
			isQuestionnaire: false,
			region: null
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		logout() {
			this.$router.replace('/');
			this.openMenu();
			this.resetGlobalUser();
		}
	},
	mounted() {
		this.isOGV = this.$route.path.split('/')[1] === 'ogv';
		this.isQuestionnaire = this.$route.path.split('/')[1] === 'questionnaire';

		if (this.isOGV) {
			this.getCurrentRegion().then((result) => {
				mutations.setRegion(result.name);
				this.region = result;
			});
		}
	}
};
</script>

<style scoped>
.active::before {
	@apply block w-3 h-14 bg-blue-500 absolute rounded-xl;
	content: '';
	left: -6px;
}
.label {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 14px;
	color: #9193a5;
	text-align: left;
	padding-top: 34px;
	margin-top: 46px;
}
.region {
	font-weight: 700;
	font-size: 18px;
	line-height: 19px;
	color: #35363d;
	text-align: left;
}
</style>
